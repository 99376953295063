import styled from 'styled-components';
import OilerLogo from '@oilerKit/assets/oiler-logo.svg';
import NaftaLogo from '@nafta/assets/NaFTa.svg';
import React from 'react';
import OilerImage from '@oilerKit/OilerImage';

const Wrapper = styled.div`
    margin-top: 38px;
    margin-bottom: 15px;
    width: 250px;

    &:hover {
        cursor: pointer;
    }
`;

const Logo = () => {
    return (
        <Wrapper onClick={() => (window.location.href = '/')}>
            <OilerImage image={NaftaLogo} alt="Nafta logo" height={45} />
        </Wrapper>
    );
};

export default Logo;
