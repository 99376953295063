export enum Network {
    Mainnet = 'Mainnet',
    Ropsten = 'Ropsten',
    Rinkeby = 'Rinkeby',
    Kovan = 'Kovan',
    GOERLI = 'Goerli',
    Unsupported = 'Unsupported Network',
    Unknown = 'Unknown Network',
}

export const getNameFromNetId = (networkVersion: string | number): string => {
    switch (networkVersion) {
        case '1':
        case '0x1':
        case 1:
            return Network.Mainnet;
        case '3':
        case '0x3':
        case 3:
            return Network.Ropsten;
        case '4':
        case '0x4':
        case 4:
            return Network.Rinkeby;
        case '5':
        case '0x5':
        case 5:
            return Network.GOERLI;
        case '42':
        case '0x2a':
        case 42:
            return Network.Kovan;
        default:
            return Network.Unsupported;
    }
};
