import { createTheme } from '@material-ui/core';
import backgroundImage from '@oilerKit/themes/light/assets/background.png';

export const theme = createTheme({
    typography: {
        fontFamily: '"Montserrat", sans-serif',
    },
    palette: {
        background: { paper: '#FFFEFA' },
        type: 'light',
        primary: {
            light: '#eeeeee',
            main: '#9e9e9e',
            dark: '#616161',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            contrastText: '#000',
        },
        secondary: {
            light: '#faf5dd',
            main: '#f3e7a9',
            dark: '#928b65',
            100: '#fdfaee',
            200: '#faf5dd',
            300: '#f8f1cb',
            400: '#f5ecba',
            500: '#f3e7a9',
            600: '#c2b987',
            700: '#928b65',
            800: '#615c44',
            900: '#312e22',
            contrastText: '#000',
        },
        error: {
            light: '#e8a5b6',
            main: '#c51e48',
            dark: '#4f0c1d',
            100: '#f3d2da',
            200: '#e8a5b6',
            300: '#dc7891',
            400: '#d14b6d',
            500: '#c51e48',
            600: '#9e183a',
            700: '#76122b',
            800: '#4f0c1d',
            900: '#27060e',
            contrastText: '#fff',
        },
        info: {
            light: '#faf5dd',
            main: '#2196f3',
            dark: '#928b65',
            100: '#fdfaee',
            200: '#faf5dd',
            300: '#f8f1cb',
            400: '#f5ecba',
            500: '#2196f3',
            600: '#1e87db',
            700: '#1a78c2',
            800: '#1769aa',
            900: '#145a92',
            contrastText: '#fff',
        },
        success: {
            light: '#99e2b7',
            main: '#00B64B',
            dark: '#00491e',
            100: '#ccf0db',
            200: '#99e2b7',
            300: '#66d393',
            400: '#33c56f',
            500: '#00B64B',
            600: '#00923c',
            700: '#006d2d',
            800: '#00491e',
            900: '#00240f',
            contrastText: '#000000',
        },
        text: {
            primary: '#fff',
            secondary: '#000',
            disabled: 'rgba(0,0,0,0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '*': {},
                '*::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                    borderRadius: 10,
                },
                '*::-webkit-scrollbar-track': {
                    background: '#e0e0e0',
                },
                '*::-webkit-scrollbar-thumb': {
                    background: '#9e9e9e',
                    borderRadius: 10,
                },
                '*::-webkit-scrollbar-thumb:hover': {
                    background: '#757575',
                },
                body: {
                    backgroundImage: `url(${backgroundImage}) !important`,
                },
            },
        },
    },
});

export default theme;
