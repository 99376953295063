import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import '@oilerKit/fonts.css';
import { Provider } from 'react-redux';
import { rootReducer } from './store';
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';
import { OilerThemeProvider, useOilerTheme } from '@oilerKit/themes/OilerThemeProvider';
import pumpJack from '@oilerKit/assets/gif/pump-jack.gif';
import { OilerToastProvider } from '@oilerKit/OilerToast/OilerToastProvider';
import styled from 'styled-components';
import { FOOTER_LINKS } from '@constants/index';
import OilerFooter from '@oilerKit/OilerFooter';
import OilerContainer from '@oilerKit/OilerContainer';
import OilerNav from '@oilerKit/OilerNav/OilerNav';
import OilerImage from '@oilerKit/OilerImage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '@nafta/LandingPage';

const NaftaApp = React.lazy(() => import('./nafta/NaftaApp'));

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['transactions'],
};

const persistedReducer = persistReducer(persistConfig, combineReducers(rootReducer));
const storeToPersist = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

const persistor = persistStore(storeToPersist);

const AppWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const Background = styled.div`
    height: 554px;
    width: 100%;
    position: absolute;
    background: ${(props) => props.theme.palette.common.black};
    z-index: -1;
`;

const LightThemeTopBlock = () => {
    const { isCurrentThemeDark } = useOilerTheme();
    return isCurrentThemeDark ? null : <Background />;
};

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={storeToPersist}>
                <PersistGate loading={null} persistor={persistor}>
                    <OilerThemeProvider>
                        <LightThemeTopBlock />
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <OilerImage image={pumpJack} height={100} />
                                </div>
                            }
                        >
                            <OilerToastProvider>
                                <AppWrapper>
                                    <OilerContainer maxWidth={false}>
                                        <OilerContainer maxWidth={'lg'}>
                                            <OilerNav />
                                        </OilerContainer>
                                        {/*<ErrorBoundary>*/}
                                        {/*<OilerIPblocker>*/}

                                        <Routes>
                                            <Route
                                                path="/"
                                                element={
                                                    <OilerContainer>
                                                        <LandingPage />
                                                    </OilerContainer>
                                                }
                                            />

                                            <Route path="/app/*" element={<NaftaApp />} />
                                        </Routes>
                                        {/*</OilerIPblocker>*/}
                                        {/*</ErrorBoundary>*/}
                                    </OilerContainer>

                                    <OilerFooter links={FOOTER_LINKS} />
                                </AppWrapper>
                            </OilerToastProvider>
                        </Suspense>
                    </OilerThemeProvider>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);
