import React from 'react';
import Logo from './Logo';
import MediaList from './MediaList';
import styled from 'styled-components';
import Menu from '@oilerKit/OilerNav/Menu';
import OilerGrid from '@oilerKit/OilerGrid';
import OilerTypography from '@oilerKit/OilerTypography';
import NaftaLamp from '@nafta/assets/nafta-lamp.mp4';
import { alpha } from '@material-ui/core/styles';

const Nav = styled.div`
    display: flex;
    //min-height: 282px;
    margin-bottom: 0px;
    background-color: transparent;
    position: relative;
    align-items: start !important;
    flex-direction: column;
    ${(props) => props.theme.breakpoints.up(630)} {
        flex-direction: row;
    }
`;

const StyledMediaList = styled(MediaList)`
    //padding-right: 10px;
    padding-left: 10px;
    ${(props) => props.theme.breakpoints.down(630)} {
        display: none !important;
    }
    ${(props) => props.theme.breakpoints.up(660)} {
        margin-left: auto;
        justify-content: space-between;
        width: 425px;
    }
`;

const StyledMenu = styled(Menu)`
    //padding-right: 10px;
    width: 100%;
    padding-left: 10px;
    ${(props) => props.theme.breakpoints.down(630)} {
        display: none !important;
    }
    ${(props) => props.theme.breakpoints.up(660)} {
        margin-left: auto;
        justify-content: space-between;
        width: 425px;
    }
`;

export interface OilerNavProps {
    showWalletBalance?: boolean;
}
const VideoWrapper = styled.div`
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 30px;

    .glow {
        ${(props) => props.theme.breakpoints.down('lg')} {
            width: 200%;
        }
        ${(props) => props.theme.breakpoints.up('lg')} {
            width: 200px;
        }
        height: 200px;
        overflow: visible;
        position: absolute;
        -webkit-animation-name: flicker;
        animation-name: flicker;
        -webkit-animation-duration: 8s;
        animation-duration: 8s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        background: radial-gradient(
            ellipse at center,
            ${alpha('#f3790c', 0.38)} 0%,
            ${alpha('#f3790c', 0)} 40%,
            ${alpha('#f3790c', 0)} 100%
        );
    }
    .video {
        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100%;
        }
        ${(props) => props.theme.breakpoints.up('sm')} {
            width: 150px;
        }
        height: 150px;
        mix-blend-mode: lighten;
        position: absolute;
    }

    .glow,
    .video {
        top: 70%;
        left: 40%;
        transform: translate(-50%, -50%);
    }

    @keyframes flicker {
        from {
            opacity: 0.5;
        }
        4% {
            opacity: 0.7;
        }
        8% {
            opacity: 0.45;
        }
        12% {
            opacity: 0.65;
        }
        16% {
            opacity: 0.3;
        }
        20% {
            opacity: 0.5;
        }
        24% {
            opacity: 0.6;
        }
        28% {
            opacity: 0.25;
        }
        32% {
            opacity: 0.3;
        }
        36% {
            opacity: 0.55;
        }
        40% {
            opacity: 0.4;
        }
        44% {
            opacity: 0.6;
        }
        48% {
            opacity: 0.35;
        }
        52% {
            opacity: 0.2;
        }
        56% {
            opacity: 0.7;
        }
        60% {
            opacity: 0.5;
        }
        64% {
            opacity: 0.65;
        }
        68% {
            opacity: 0.35;
        }
        72% {
            opacity: 0.5;
        }
        76% {
            opacity: 0.4;
        }
        80% {
            opacity: 0.65;
        }
        84% {
            opacity: 0.4;
        }
        88% {
            opacity: 0.35;
        }
        92% {
            opacity: 0.6;
        }
        96% {
            opacity: 0.3;
        }
        to {
            opacity: 0.5;
        }
    }
`;

const Video = () => {
    return (
        <VideoWrapper>
            <video className="video" height="100%" autoPlay loop muted>
                <source src={NaftaLamp} type="video/mp4" />
            </video>
            <div className="glow" />
        </VideoWrapper>
    );
};

const OilerNav = () => {
    return (
        <Nav>
            <OilerGrid container>
                <Video />
                <OilerGrid>
                    <OilerGrid item xs={12}>
                        <Logo />
                    </OilerGrid>
                    <OilerGrid item xs={12}>
                        <OilerTypography variant={'h6'} weight="semiBold">
                            Flash-owned NFTs
                        </OilerTypography>
                    </OilerGrid>
                </OilerGrid>
            </OilerGrid>
            <StyledMenu />
            {/*<StyledMediaList />*/}
        </Nav>
    );
};

export default OilerNav;
