import React, { createContext, ReactNode, useContext, useState } from 'react';
import {
    createTheme,
    CssBaseline,
    Theme,
    ThemeProvider,
} from '@material-ui/core';
import {
    createGlobalStyle,
    ThemeProps,
    ThemeProvider as StyledThemeProvider,
} from 'styled-components';
import dark from '@oilerKit/themes/dark/dark';
import light from '@oilerKit/themes/light/light';
import darkBackgroundImage from '@oilerKit/themes/dark/assets/background.png';
import lightBackgroundImage from '@oilerKit/themes/light/assets/background.png';

const GlobalStyles = createGlobalStyle`
    body {
      overflow-x: hidden;

      font-weight: ${(props: ThemeProps<any>) =>
          props.theme.typography.fontWeightMedium};
    }
`;

interface OilerThemeProviderProps {
    children: ReactNode;
}

const Themes: {
    [key: string]: any;
} = {
    dark,
    light,
};

interface ThemeContextProps {
    currentThemeName: string;
    setTheme: (name: string) => void;
    isCurrentThemeDark: boolean;
}

const ThemeContext = createContext({} as ThemeContextProps);

export const OilerThemeProvider = (props: OilerThemeProviderProps) => {
    const localStorageThemeKey = 'oilerAppTheme';
    const [currentThemeName, setThemeName] = useState(
        localStorage.getItem(localStorageThemeKey) || 'dark',
    );
    const theme = Themes[currentThemeName];
    const isCurrentThemeDark = currentThemeName === 'dark';

    const setTheme = (name: string) => {
        localStorage.setItem(localStorageThemeKey, name);
        setThemeName(name);
    };

    const contextValue = {
        currentThemeName,
        setTheme,
        isCurrentThemeDark,
    };

    return (
        <ThemeContext.Provider value={contextValue}>
            <StyledThemeProvider theme={{ ...theme }}>
                <ThemeProvider theme={{ ...theme }}>
                    <GlobalStyles />
                    <CssBaseline />
                    {props.children}
                </ThemeProvider>
            </StyledThemeProvider>
        </ThemeContext.Provider>
    );
};

export const useOilerTheme = () => useContext(ThemeContext);
