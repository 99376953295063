import OilerGrid from '@oilerKit/OilerGrid';
import OilerLink from '@oilerKit/OilerLink';
import OilerTypography from '@oilerKit/OilerTypography';
import { NAFTA_GITBOOK_URL } from '@constants/index';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledMenu = styled(OilerGrid)`
    .active h6 {
        color: ${(props) => props.theme.palette.primary.main};
        font-weight: ${(props) => props.theme.typography.fontWeightBold};
    }
    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: ${(props) => props.theme.palette.primary[600]};
        }
    }
`;

const Menu = () => {
    return (
        <StyledMenu mt={'38px'} container spacing={5} justifyContent={'flex-end'} direction={'row'}>
            <OilerGrid item>
                <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>
                    <OilerTypography weight={'light'} variant={'h6'}>
                        NAFTA
                    </OilerTypography>
                </NavLink>
            </OilerGrid>
            <OilerGrid item>
                <NavLink className={({ isActive }) => (isActive ? 'active' : '')} to="/app">
                    <OilerTypography weight={'light'} variant={'h6'}>
                        APP
                    </OilerTypography>
                </NavLink>
            </OilerGrid>
            <OilerGrid item>
                <OilerLink href={NAFTA_GITBOOK_URL} target={'_blank'}>
                    <OilerTypography weight={'light'} variant={'h6'}>
                        DOCS
                    </OilerTypography>
                </OilerLink>
            </OilerGrid>
        </StyledMenu>
    );
};

export default Menu;
