import React from 'react';
import styled from 'styled-components';
import { ReactComponent as twitter } from '@oilerKit/assets/icons/social/twitter.svg';
import { ReactComponent as telegram } from '@oilerKit/assets/icons/social/telegram.svg';
import { ReactComponent as discord } from '@oilerKit/assets/icons/social/discord.svg';
import { ReactComponent as github } from '@oilerKit/assets/icons/social/github.svg';
import { ReactComponent as medium } from '@oilerKit/assets/icons/social/medium.svg';
import { ReactComponent as gitbook } from '@oilerKit/assets/icons/social/gitbook.svg';
import OilerIcon from '../OilerIcon';
import {
    DISCORD_URL,
    GITHUB_URL,
    MEDIUM_URL,
    OILER_GITBOOK_URL,
    TELEGRAM_URL,
    TWITTER_URL,
} from '@constants/index';
import OilerLink from '@oilerKit/OilerLink';
import { useOilerTheme } from '@oilerKit/themes/OilerThemeProvider';
import { useMediaQuery, useTheme } from '@material-ui/core';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 40px 0;
    width: 100%;
`;

const MediaList = (props: any) => {
    const { isCurrentThemeDark } = useOilerTheme();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(630));
    const color = isCurrentThemeDark
        ? theme.palette.common.white
        : isSmallScreen
        ? theme.palette.common.black
        : theme.palette.secondary.main;

    return (
        <Wrapper {...props}>
            <OilerLink
                aria-label="Twitter link"
                href={TWITTER_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={twitter}
                    alt="Twitter logo"
                />
            </OilerLink>
            <OilerLink
                aria-label="Telegram link"
                href={TELEGRAM_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={telegram}
                    alt="Telegram logo"
                />
            </OilerLink>
            <OilerLink
                aria-label="Discord link"
                href={DISCORD_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={discord}
                    alt="Discord logo"
                />
            </OilerLink>
            <OilerLink
                aria-label="Github link"
                href={GITHUB_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={github}
                    alt="GitHub logo"
                />
            </OilerLink>
            <OilerLink
                aria-label="Medium link"
                href={MEDIUM_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={medium}
                    alt="Medium logo"
                />
            </OilerLink>
            <OilerLink
                aria-label="Gitbook link"
                href={OILER_GITBOOK_URL}
                target="_blank"
            >
                <OilerIcon
                    htmlColor={color}
                    fontSize={'small'}
                    component={gitbook}
                    alt="Gitbook logo"
                />
            </OilerLink>
            {/*<a href={DEFIPULSE_URL} target="_blank" rel="noopener noreferrer">*/}
            {/*    <OilerIcon icon={defipulse} alt="DeFi Pulse logo" />*/}
            {/*</a>*/}
        </Wrapper>
    );
};

export default MediaList;
