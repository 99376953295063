import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

interface OilerIconProps extends SpaceProps, SvgIconProps {
    alt?: string;
    component: FunctionComponent;
}

const StyledSvgIcon = styled(SvgIcon)`
    ${space}
`;

const OilerIcon = (props: OilerIconProps) => {
    return (
        <StyledSvgIcon aria-label={props.alt} {...props}>
            {props.component}
        </StyledSvgIcon>
    );
};

export default OilerIcon;
