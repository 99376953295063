import React from 'react';
import styled, { css } from 'styled-components';
import { space, SpaceProps, width, WidthProps } from 'styled-system';
import { Button, ButtonProps } from '@material-ui/core';
import ConditionalLink from '@oilerKit/OilerButton/ConditionalLink';
import getThemeProperty from '@oilerKit/themes/themeProperty';
import { useOilerTheme } from '@oilerKit/themes/OilerThemeProvider';

const disabledProperties = css`
    background: ${(props) => props.theme.palette.primary[800]};
    border-color: ${(props) => props.theme.palette.primary[800]};
`;

const StyledButton = styled(Button)`
    &.MuiButton-primary {
        color: ${(props) => props.theme.palette.secondary.contrastText};
    }

    &.MuiButton-containedSecondary {
        &:disabled {
            border-color: ${(props) =>
                getThemeProperty(null, props.theme.palette.primary[400])};
        }

        &:hover {
            background: ${(props) =>
                getThemeProperty(null, props.theme.palette.secondary[600])};
        }
    }

    &.MuiButton-containedPrimary {
        background: ${(props) =>
            getThemeProperty(null, props.theme.palette.primary[400])};
        color: ${(props) =>
            getThemeProperty(null, props.theme.palette.primary[900])};
    }

    &.MuiButton-textPrimary {
        color: ${(props) =>
            getThemeProperty(null, props.theme.palette.common.black)};

        &:hover {
            background: ${(props) =>
                getThemeProperty(null, props.theme.palette.primary[300])};
        }

        &.Mui-disabled {
            color: ${(props) => props.theme.palette.action.disabled};
        }
    }

    &.MuiButton-textSecondary {
        color: ${(props) =>
            getThemeProperty(null, props.theme.palette.secondary[600])};

        &:hover {
            background: ${(props) =>
                getThemeProperty(null, props.theme.palette.secondary[300])};
        }

        &.Mui-disabled {
            color: ${(props) => props.theme.palette.action.disabled};
        }
    }

    &.MuiButton-outlinedPrimary {
        border: 2px solid
            ${(props) =>
                getThemeProperty(
                    props.theme.palette.primary.main,
                    props.theme.palette.common.black,
                )};
        background: ${() => getThemeProperty(null, null)};
        color: ${(props) =>
            getThemeProperty(null, props.theme.palette.primary[900])};
        &:hover {
            background: ${(props) =>
                getThemeProperty(null, props.theme.palette.primary[300])};
            color: ${(props) =>
                getThemeProperty(
                    null,
                    props.theme.palette.primary.contrastText,
                )};
        }
        &.Mui-disabled {
            border-color: ${(props) => props.theme.palette.action.disabled};
        }
    }

    &.MuiButton-outlinedSecondary {
        border: 2px solid
            ${(props) =>
                getThemeProperty(null, props.theme.palette.common.black)};
    }

    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    border-radius: ${(props) => props.theme.shape.borderRadius}px;

    & svg {
        height: 1rem;
    }
    ${space}
    ${width}
`;

export interface OilerButtonProps extends ButtonProps, SpaceProps, WidthProps {
    fullWidth?: boolean;
    target?: '_blank';
}

const OilerButton = (props: OilerButtonProps) => {
    const { color = 'primary', target, href } = props;

    return (
        <ConditionalLink condition={!!target} target={target} href={href}>
            <StyledButton
                href={undefined}
                color={color}
                variant={`contained`}
                {...props}
                disableElevation
                disableRipple
                disableFocusRipple
                disableTouchRipple
            >
                {props.children}
            </StyledButton>
        </ConditionalLink>
    );
};

export default OilerButton;
