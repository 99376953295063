import { useTheme } from '@material-ui/core';
import { Theme } from '@oilerKit/themes/types';

const getThemeProperty = (
    darkProperty: string | number | null,
    lightProperty: string | number | null,
) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    return theme.palette.type === Theme.DARK ? darkProperty : lightProperty;
};

export default getThemeProperty;
