import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface OilerIconProps extends SpaceProps, LayoutProps {
    image: string;
    alt?: string;
}

const Wrapper = styled.div<OilerIconProps>`
    ${space}
    ${layout}
`;

const StyledImg = styled.img`
    display: flex;
    width: auto;
    height: inherit;
`;

const OilerImage = (props: OilerIconProps) => {
    const { image, height, alt } = props;
    const styles = height
        ? {
              width: 'auto',
          }
        : {};

    return (
        <Wrapper {...props} style={styles}>
            <StyledImg src={image} alt={alt} />
        </Wrapper>
    );
};

export default OilerImage;
