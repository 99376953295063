import siNumber from 'si-number';

export const formatNbToSI = (
    number: string | number | undefined,
    options?: any,
) => {
    if (number === undefined || isNaN(Number(number))) return;
    let nb = number;
    if (typeof number === 'string') nb = parseFloat(number);

    const opts = options ?? { decimal: '.', precision: 2, thousands: true };

    return nb === 0 ? '0.00' : siNumber(nb, opts);
};
