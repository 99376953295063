import OilerGrid from '@oilerKit/OilerGrid';
import OilerImage from '@oilerKit/OilerImage';
import NaftaLogo from '@nafta/assets/NaFTa.svg';
import NaftaLamp from '@nafta/assets/nafta-lamp.mp4';
import OilerTypography from '@oilerKit/OilerTypography';
import OilerButton from '@oilerKit/OilerButton/OilerButton';
import styled from 'styled-components';
import OilerContainer from '@oilerKit/OilerContainer';
import { alpha } from '@material-ui/core/styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

const Cta = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const margin = isSmallScreen ? 5 : 0;

    return (
        <OilerGrid
            item
            md={12}
            container
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'stretch'}
            mt={margin}
        >
            <OilerGrid item xs={12}>
                <OilerImage height={isSmallScreen ? 55 : 85} image={NaftaLogo} />
            </OilerGrid>
            <OilerGrid item xs={12}>
                <OilerTypography variant={'h4'} mt={3}>
                    Flash-owned NFTs
                </OilerTypography>
            </OilerGrid>
            <OilerGrid item md mt={5}>
                <OilerButton variant={'outlined'}>LEARN MORE</OilerButton>
            </OilerGrid>
        </OilerGrid>
    );
};

const VideoWrapper = styled.div`
    width: 500px;
    height: 500px;
    position: relative;

    .glow {
        ${(props) => props.theme.breakpoints.down('lg')} {
            width: 100%;
        }
        ${(props) => props.theme.breakpoints.up('lg')} {
            width: 700px;
        }
        height: 700px;
        overflow: visible;
        position: absolute;
        -webkit-animation-name: flicker;
        animation-name: flicker;
        -webkit-animation-duration: 8s;
        animation-duration: 8s;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        background: radial-gradient(
            ellipse at center,
            ${alpha('#f3790c', 0.38)} 0%,
            ${alpha('#f3790c', 0)} 40%,
            ${alpha('#f3790c', 0)} 100%
        );
    }
    .video {
        ${(props) => props.theme.breakpoints.down('sm')} {
            width: 100%;
        }
        ${(props) => props.theme.breakpoints.up('sm')} {
            width: 500px;
        }
        height: 500px;
        mix-blend-mode: lighten;
        position: absolute;
    }

    .glow,
    .video {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @keyframes flicker {
        from {
            opacity: 0.5;
        }
        4% {
            opacity: 0.7;
        }
        8% {
            opacity: 0.45;
        }
        12% {
            opacity: 0.65;
        }
        16% {
            opacity: 0.3;
        }
        20% {
            opacity: 0.5;
        }
        24% {
            opacity: 0.6;
        }
        28% {
            opacity: 0.25;
        }
        32% {
            opacity: 0.3;
        }
        36% {
            opacity: 0.55;
        }
        40% {
            opacity: 0.4;
        }
        44% {
            opacity: 0.6;
        }
        48% {
            opacity: 0.35;
        }
        52% {
            opacity: 0.2;
        }
        56% {
            opacity: 0.7;
        }
        60% {
            opacity: 0.5;
        }
        64% {
            opacity: 0.65;
        }
        68% {
            opacity: 0.35;
        }
        72% {
            opacity: 0.5;
        }
        76% {
            opacity: 0.4;
        }
        80% {
            opacity: 0.65;
        }
        84% {
            opacity: 0.4;
        }
        88% {
            opacity: 0.35;
        }
        92% {
            opacity: 0.6;
        }
        96% {
            opacity: 0.3;
        }
        to {
            opacity: 0.5;
        }
    }
`;

const Video = () => {
    return (
        <VideoWrapper>
            <video className="video" height="100%" autoPlay loop muted>
                <source src={NaftaLamp} type="video/mp4" />
            </video>
            <div className="glow" />
        </VideoWrapper>
    );
};

const StyledOilerGrid = styled.div`
    margin-top: 50px;
    margin-right: -24px;
`;

const Wrapper = styled.div`
    background: linear-gradient(90deg, rgba(243, 231, 169, 0) 43%, rgba(0, 0, 0, 1) 100%);
`;

const Banner = () => {
    return (
        <StyledOilerGrid>
            <Wrapper>
                <OilerContainer maxWidth={'lg'}>
                    <OilerGrid container>
                        <OilerGrid container alignItems={'center'} item md={6}>
                            <Cta />
                        </OilerGrid>
                        <OilerGrid item md={6} container justifyContent={'center'}>
                            <Video />
                        </OilerGrid>
                    </OilerGrid>
                </OilerContainer>
            </Wrapper>
        </StyledOilerGrid>
    );
};

export default Banner;
